import { LANGS } from '../../App';
import { FooterItem } from './FooterItem/FooterItem';
import { CopyrightBox } from './CopyrightBox/CopyrightBox';
import { SocialMediaAddress } from '@lk-gtcom/ecomlab-components';
import './Footer.scss'

const visa = require('./img/visa.png')
const masterCard = require('./img/masterCard.png')
const google = require('./img/googlePay.png')
const samsung = require('./img/samsungPay.png')
const troy = require('./img/troy.png')

const footerItems = [
    {
        label: 'Services',
        subItems: [
            {
                label: 'Category analysis',
                path: 'https://lk.gtcom.io/category-analysis/goods',
                blank: true
            },
            {
                label: 'Niche search',
                path: 'https://lk.gtcom.io/niche-selection',
                blank: true
            },
            {
                label: 'Article analysis',
                path: 'https://lk.gtcom.io/sku-analysis/information',
                blank: true
            },
            {
                label: 'Brand analysis',
                path: 'https://lk.gtcom.io/brand-analysis/goods',
                blank: true
            },
            {
                label: 'Sellers analysis',
                path: 'https://lk.gtcom.io/seller-analysis/goods',
                blank: true
            },
        ],
    },
    {
        label: 'ABOUT US',
        subItems: [
            {
                label: 'Mission',
                path: 'https://gtcom.io/articles/Company/Mission_of_GTCOM/572',
                blank: true
            },
            {
                label: 'About Company',
                path: 'https://gtcom.io/articles/Company/About_company/613',
                blank: true
            },
            {
                label: 'Offices',
                path: 'https://gtcom.io/articles/Company/Offices/582',
                blank: true
            },
            {
                label: 'News',
                path: 'https://gtcom.io/articles/News',
                blank: true
            },
        ],
    }
];

const footerItemsTur = [
    {
        label: 'HİZMETLER',
        subItems: [
            {
                label: 'Kategori analizi',
                path: 'https://lk.gtcom.io/category-analysis/goods',
                blank: true
            },
            {
                label: 'Niş arama',
                path: 'https://lk.gtcom.io/niche-selection',
                blank: true
            },
            {
                label: 'Makale analizi',
                path: 'https://lk.gtcom.io/sku-analysis/information',
                blank: true
            },
            {
                label: 'Marka analizi',
                path: 'https://lk.gtcom.io/brand-analysis/goods',
                blank: true
            },
            {
                label: 'Satıcı analizi',
                path: 'https://lk.gtcom.io/seller-analysis/goods',
                blank: true

            },
        ],
    },
    {
        label: 'Hakkımızda',
        subItems: [
            {
                label: 'Misyonumuz',
            },
            {
                label: 'Şirket bilgileri',
                path: ''
            },
            {
                label: 'Ofisler',
            },
            {
                label: 'Haberler',
            },
        ],
    }
];


const Footer = ({ activeLang, portal_id }) => {

    const activeMenu = (activeLang === LANGS.TUR) ? footerItemsTur : footerItems
    const transactionPlatforms = [
        troy, visa, masterCard, google, samsung
    ]

    return (
        <footer className="footer">
            <div className='footer__content'>
                <div className="footer__upper-box">
                    <nav className="footer__nav">
                        {activeMenu?.map(({ label, subItems }) => (
                            <FooterItem
                                key={label}
                                label={label}
                                subItems={subItems}
                            />
                        ))}
                        <div className="footer__item">
                            <h4 className="footer__title">GTCOM.IO</h4>
                            <address className="footer__address">
                                <ul className="footer__list">
                                    <li className="footer__list_item">
                                        <a href="mailto:info@gtcom.io" className="address__link">info@gtcom.io</a>
                                    </li>
                                    <li className="footer__list_item">
                                        <a href="#" className="address__link">
                                            {activeLang === LANGS.TUR ? 'Destek' : 'Support'}</a>
                                    </li>
                                    <li className="footer__list_item">
                                        <a href="#" className="address__link">
                                            iCERENKOY MAHALLESI USKUDAR-
                                            iCERENKOY YOLU CAD. OZIS SiT.
                                            OFiS ATASEHIR AP. NO 21/6
                                        </a>
                                    </li>
                                </ul>
                            </address>
                        </div>
                    </nav>

                    <SocialMediaAddress
                        activeLang={activeLang}
                        portal_id={portal_id}
                    />
                </div>
                <div className='footer__bottom-box'>
                    <CopyrightBox activeLang={activeLang}/>
                </div>
                <div className="footer__org_info" style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                    <div className="footer_org_inner" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%', flexWrap: 'wrap', gap: '10px' }}>
                        <span style={{ fontSize: 12 }}>{activeLang === LANGS.TUR ? 'Tüzel kişiler için nakitsiz ödeme' : 'Cashless payment for legal entities and sole proprietors'}</span>
                        {
                            transactionPlatforms?.map((el, ind) => {
                                return <img src={el} alt="Фото платежной платформы" key={el + ind} />
                            })
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}

export { Footer }