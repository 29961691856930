import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TileLongIcon } from './img/tile-long_icon.svg'
import { MultilevelSidebar, Breadcrumb } from '@lk-gtcom/ecomlab-components';
import { ReactComponent as TableIcon } from './img/table_icon.svg'
import { ReactComponent as TileIcon } from './img/tile_icon.svg'
import { handleApiResponseOrThrow } from '../../common/utils';
import { RavshanArticlesHttps } from '../../fetchUrls';
import { useState, useEffect } from 'react'
import { useResetOnBaseURL } from '../../common/hooks/useResetOnBaseURL'
import { LANGS, PORTAL_ID } from '../../App';
import { ArticleMain } from "../../components/ArticleMain/ArticleMain";
import { Video } from '../Video/Video'
import Article from '../../components/Article/Article';
import "./Main.scss"

export const BASE_ROUTE = 'articles'

const pathBreadLang = [
    {
        path: 'products',
        tur: 'ürünler'
    },
    {
        path: 'solutions',
        tur: 'çözümler'
    },
    {
        path: 'cases',
        tur: 'Başari Hikayeleri'
    },
    {
        path: 'news',
        tur: 'Haberler'
    },
    {
        path: 'study',
        tur: 'Eğitim'
    },
]

const Main = ({ activeLang, pathBread }) => {

    const path = useParams()['*']
    const navigate = useNavigate()
    const [hideMenu, setHideMenu] = useState(true)
    const [langPath, setLangPath] = useState('')
    const [selectedEndLevelValue, setSelectedEndLevelValue] = useState(0)
    const [initialRender, setInitialRender] = useState(true)
    const [currentArticle, setCurrentArticle] = useState({})

    const [selectedTags, setSelectedTags] = useState([])
    const [tags, setTags] = useState([])
    const [total, setTotal] = useState(0)


    const [selectView, setSelectView] = useState('Tile-short')
    const [sidebarState, setSidebarState] = useState([])

    const fetchSidebar = () => {
        setSidebarState([])

        const url = `${RavshanArticlesHttps}/get_portals_levels`

        const headers = {
            'language': activeLang.toLowerCase(),
            'portal-id': PORTAL_ID,
            'Accept': 'application/json'
        }

        fetch(url, { headers })
            .then(res => handleApiResponseOrThrow(res))
            .then(json => setSidebarState(prev => [...prev, ...json]))
            .catch(err => console.error(err))
    }

    useResetOnBaseURL(BASE_ROUTE, () => {
        setSelectedEndLevelValue(0)
    })

    useEffect(() => {
        if (initialRender) {
            fetchSidebar()
            setInitialRender(false)
        }
    }, [])

    useEffect(() => {
        if (initialRender) return
        navigate(BASE_ROUTE)
        fetchSidebar()
        setSelectedEndLevelValue(0)
    }, [activeLang])



    const saveWidthColumn = () => {
        if (selectView == 'Table') {
            const colWidth = [...document.querySelectorAll('.col-th-border')]
            if (colWidth.length > 0) {
                const arrWidth = colWidth.map((el) => el.style.width ? el.style.width : '247px')
                sessionStorage.setItem('col-width', arrWidth);
            }
        }
    }

    useEffect(() => {
        saveWidthColumn()
    }, [selectedEndLevelValue]);


    useEffect(() => {
        pathBreadLang.forEach((item) => {
            if ((item.path == pathBread) && (activeLang === LANGS.TUR)) {
                setLangPath(item.tur)
            }
            if ((item.path == pathBread) && (activeLang === LANGS.ENG)) {
                setLangPath(item.path)
            }
        })
    }, [pathBread, activeLang])

    useEffect(() => {
        setTotal(null)
    }, [path])
    
    return (
        <>
            <div className='breadcrumb-and-select-view-box' >
                <Breadcrumb
                    activeLang={activeLang}
                    breadcrumbs={currentArticle?.breadcrumbs}
                    elementTitle={currentArticle?.title}
                    baseRoute={BASE_ROUTE}
                    noPortal={true}
                    newsCount={total}
                    hideTitle={!(path?.split('/')?.indexOf(BASE_ROUTE) >= 0)}
                />
                {!Object.keys(currentArticle).length &&
                    <div className='select-view-box'>
                        <button className={selectView == 'Tile-short' ? 'btn_tile-short_active' : 'btn_tile-short'}
                            onClick={(e) => {
                                saveWidthColumn()
                                setSelectView('Tile-short')
                            }
                            }
                        >
                            <TileIcon />
                        </button>
                        <button className={selectView == 'Tile-long' ? 'btn_tile-long_active' : 'btn_tile-long'}
                            onClick={(e) => {
                                saveWidthColumn()
                                setSelectView('Tile-long')
                            }
                            }
                        >
                            <TileLongIcon />
                        </button>
                        <button className={selectView == 'Table' ? 'btn_table_active' : 'btn_table'}
                            onClick={(e) => {
                                saveWidthColumn()
                                setSelectView('Table')
                            }}
                        >
                            <TableIcon />
                        </button>
                    </div>
                }
            </div>
            <div className='settings__content' >
                <div className='flex' >
                    <div className={hideMenu ? 'settings__box' : 'settings__box_active'}>
                        <nav className='settings__nav'>
                            <MultilevelSidebar
                                maxDepth={1}
                                data={sidebarState}
                                mainRoute={pathBread}
                                baseRoute={BASE_ROUTE}
                                setData={(e) => setSidebarState(e)}
                                onLevelClick={id => setSelectedEndLevelValue(id)}
                            />
                        </nav>
                    </div>
                    <div className='settings__main-box_white' style={{ overflow: selectView == 'Table' ? 'hidden' : 'visible' }} >
                        <Routes>
                            <Route path={`/${BASE_ROUTE}/*`}
                                element={
                                    <ArticleMain
                                        tags={tags}
                                        setTags={(e) => setTags(e)}
                                        activeLang={activeLang}
                                        selectedEndLevelValue={selectedEndLevelValue}
                                        pathBread={pathBread}
                                        selectView={selectView}
                                        selectedTags={selectedTags}
                                        setSelectedTags={(e) => setSelectedTags(e)}
                                        setTotalParent={setTotal}
                                    />
                                }
                            />
                            <Route path='/article/*' element={
                                <Article
                                    activeLang={activeLang}
                                    pathBread={pathBread}
                                    setCurrentArticle={setCurrentArticle}
                                    setTags={(e) => setTags(e)}
                                    tags={tags}
                                />
                                
                            } />
                            <Route path='/video' element={< Video />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Main }