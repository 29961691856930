import { Link } from 'react-router-dom';
import { useState } from 'react'
import { useEffect } from 'react';
import { BASE_ROUTE } from '../../pages/Main/Main';
import { useNavigate } from 'react-router-dom';
import drop from './img/drop.svg'
import './DropMenuMobileVersion.scss';

function DropMenuMobileVersion({ items, isOpenMobileMenu, setOpen }) {

    const [activeItem, setActiveItem] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate]);

    useEffect(() => {
        if(!isOpenMobileMenu) {
            setActiveItem(null)
        }
    }, [isOpenMobileMenu]);

    return (
        <div className={isOpenMobileMenu ? 'drop-menu-mobile-version_active' : 'drop-menu-mobile-version'}>
            {items?.map(({ label, subItemsMenu }, ind) => {
                return <div>
                    <div className='drop-item'
                        key={ind}
                        onClick={(e) => {
                            if (activeItem == label) {
                                setActiveItem(null)
                            } else {
                                setActiveItem(label)
                            }
                        }}
                    >
                        <a className='menu-item' href='#'>{label}</a>
                        {subItemsMenu?.length > 0 && <img src={drop} style={{transform: label == activeItem ? 'rotate(180deg)' : ''}}/>}
                    </div>
                    {((subItemsMenu?.length > 0)) && <div className={label == activeItem ? 'drop-box_active' : 'drop-box'}>
                        {subItemsMenu?.map(({ label_item, icon, path }, ind) =>
                            <div className='menu-subitem' key={ind}>
                                <Link to={`/main/${BASE_ROUTE}/${label}/${label_item}`} className='menu-subitem__item' onClick={(e) => {
                                    setOpen(false)
                                }}>{label_item}</Link>
                            </div>
                        )}
                    </div>}
                </div>
            }
            )}
            <div className='btn-container'>
                <button className='btn__green'>Вход/реакция</button>
            </div>
        </div>
    );
}

export { DropMenuMobileVersion };