import { Link } from 'react-router-dom';
import banner from './img/banner-404.svg'
import "./Page404.scss"
import { Helmet } from 'react-helmet-async';

const translation = {
    title: {
        rus: 'Не удалось найти эту страницу',
        eng: 'Page not found',
    },
    title_medium: {
        rus: 'Вернуться на главную',
        eng: 'Back to the main page',
    }
}

const Page404 = ({ activeLang = 'ru' }) => {

    return (
        <div className='page-404'>
            <Helmet>
                <title>404. Статья не найдена</title>
                <meta name="description" content="Статья не найдена" />
                <meta name="robots" content="noindex" />
            </Helmet>
            <h2 className='title'>{translation.title[activeLang]}</h2>
            <Link to='/' className='title_medium'>{translation.title_medium[activeLang]}</Link>
            <img src={banner} />
        </div>
    )
}

export { Page404 }