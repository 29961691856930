import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LogoBox } from '../Logo/Logo';
import { useNavigate } from 'react-router-dom';
import { DropMenuItem } from '../DropMenuItem/DropMenuItem'
import { DropMenuMobileVersion } from '../DropMenuMobileVersion/DropMenuMobileVersion';
import { LANGS } from '../../App';
import lang from './img/lang.svg'
import "./Header.scss"

import competitors_analysis from './img/Products/Competitors_analysis.svg'
import key_words_search_engine from './img/Products/Key_words_search_engine.svg'
import listing_creation_service from './img/Products/Listing_creation_service.svg'
import Stockc_stats from './img/Products/Stockc_stats.svg'
import product_database from './img/Products/Product_database.svg'
import trendyol_analisys from './img/Products/Trendyol_analisys.svg'
import banner_trendyol from './img/banner_trendyol.svg'

import for_retailers from './img/Solutions/for_retailers.svg'
import for_marketing_agencies from './img/Solutions/for_marketing_agencies.svg'
import for_fullfilment from './img/Solutions/for_fullfilment.svg'
import for_brands from './img/Solutions/for_brands.svg'
import banner_dashboards from './img/Solutions/banner_dashboards.svg'
import banner_competitors_analisys from './img/Solutions/banner_competitors_analisys.svg'
import competitors_analisyss from './img/Solutions/competitors_analisys.svg'
import dashboards from './img/Solutions/dashboards.svg'

import price_management from './img/управление ценами.svg'

const Header = ({ activeLang, setActiveLang, activeMenuItems, setActiveMenuItems }) => {
    const [isOpenSelect, setIsOpenSelect] = useState(false)
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
    const [widtWindow, setWidthWindow] = useState(null)
    const [isOpenInput, setIsOpenInput] = useState(false)
    const [isOpenMenu, setIsOpenMenu] = useState(null)
    const navigate = useNavigate()
    const path = useParams()

    const BASE_ROUTE = 'articles'

    const turMenu = [
        {
            label: 'Başla',
            path: '/to-begin'
        },
        {
            label: 'ürünler',
            path: `products/${BASE_ROUTE}`
            //path: 'command'
        },
        {
            label: 'çözümler',
            path: `solutions/${BASE_ROUTE}`
            //path: 'coming-soon'
        },
        {
            label: 'Başari Hikayeleri',
            path: `cases/${BASE_ROUTE}`
        },
        {
            label: 'Haberler',
            path: `news/${BASE_ROUTE}`
            //path: 'banner'
        },
        {
            label: 'Eğitim',
            //path: 'ozon-page'
            path: `study/${BASE_ROUTE}`
        },
    ]

    const enMenu = [
        {
            label: 'To begin',
            absolute: true
        },
        {
            label: "1 lev"
        },
        {
            label: "first lev",
            subItemsMenu: [
                {
                    label_item: "2 lev"
                }
            ]
        },
        {
            label: "services",
            subItemsMenu: [
                {
                    label_item: "parsing",
                }
            ]
        },
        {
            label: "cases"
        }
    ]

    const translateLogin = () => {
        if (activeLang == 'tur') return 'Giriş yap'
        if (activeLang == 'en') return 'Log in'
        return 'Log in'
    }

    const translateSignup = () => {
        if (activeLang == 'tur') return 'Üye O'
        if (activeLang == 'en') return 'Sign up'
        return 'Sign up'
    }

    useEffect(() => {
        window.onresize = () => {
            //  ReactDOM.render(<App />, document.getElementById('root'));
            setWidthWindow(window.innerWidth)
            setIsOpenMobileMenu(false)
        };
    });

    useEffect(() => {
        setWidthWindow(window.innerWidth)
    }, []);

    const menu_items_disactive = [
        {
            label: 'Products',
            // path: '/command',
            subItemsMenu: [
                {
                    label_item: 'Product database',
                    description: 'Full statistics on products, brands and categories on Trendyol and others',
                    path: '172',
                    icon: product_database
                },
                {
                    label_item: 'Competitors analysis',
                    description: 'Discover your competitors result and track there key poimts',
                    path: '11',
                    icon: competitors_analysis
                },
                {
                    label_item: 'Stockc stats',
                    description: 'Uniq updated info on stock and sales',
                    path: '12',
                    icon: Stockc_stats
                },
                {
                    label_item: 'Listing creation service',
                    description: 'We create best in class listing',
                    path: '13',
                    icon: listing_creation_service
                },
                {
                    label_item: 'Key words search engine',
                    description: 'Increase your Ads perfomance with our key word engine',
                    path: '14',
                    icon: key_words_search_engine
                },
                {
                    label_main_1: 'Login to see Trendyol analisys',
                    description_main_1: 'Free 1 month trial',
                    path_1: '',
                    icon_main_1: trendyol_analisys,
                    img_main_1: banner_trendyol
                },
            ]
        },
        {
            label: 'Solutions',
            //  path: '',
            subItemsMenu: [
                {
                    label_item: 'For brands',
                    icon: for_brands,
                    path: '25'
                },
                {
                    label_item: 'For retailers',
                    icon: for_retailers,
                    path: '26'
                },
                {
                    label_item: 'For fullfilment',
                    path: '27',
                    icon: for_fullfilment
                },
                {
                    label_item: 'For marketing agencies',
                    path: '28',
                    icon: for_marketing_agencies
                },
                {
                    label_main_2: 'Competitors Analisys',
                    description_main_2: '',
                    path_2: '',
                    icon_main_2: competitors_analisyss,
                    img_main_2: banner_competitors_analisys
                },
                {
                    label_main_2: 'Dashboards',
                    description_main_2: '',
                    path_2: '',
                    icon_main_2: dashboards,
                    img_main_2: banner_dashboards
                },
            ]
        },
        {
            label: 'Cases',
            //   path: `/main/${BASE_ROUTE}`,
            subItemsMenu: [
                {
                    label_item: 'Competitors analysis',
                    description: 'How we helped to increase knowledge on market prices and products',
                    path: '34'
                },
                {
                    label_item: 'Analytics dashboard',
                    description: 'Dashboard that helps to increase revenue',
                    path: '36'
                },
                {
                    label_item: 'E-commerce 360',
                    description: 'Full-time support service',
                    path: '37'
                },
                {
                    label_item: 'Know your market',
                    description: 'E-commerce distribution control for E-grocery',
                    path: '38'
                },
                {
                    label_main_3: 'Управление ценами',
                    description_main_3: 'Автоматизация цен и остатков исходя из себестоимости',
                    path_3: '',
                    img_main_3: price_management
                },
            ]
        },
        {
            label: 'News',
            path: ''
        },
        {
            label: 'E-comm knowledge base',
            path: ''
        },
        {
            label: 'For partners',
            path: ''
        }]

    const menu_items_eng = [
        {
            label: 'About us',
            path: `/${BASE_ROUTE}/Company`,
            // articles: true
        },
        {
            label: 'News',
            path: `/${BASE_ROUTE}`,
            articles: true
        },
        {
            label: 'Cases',
            path: `/${BASE_ROUTE}`,
            articles: true
        },
        {
            label: 'Pricing',
            path: `/pricing`,
        },
    ]

    const menu_items_tur = [
        {
            label: 'Hakkımızda',
            path: `/${BASE_ROUTE}/Şirket`,
            articles: false
        },
        {
            label: 'Haberler',
            path: `/${BASE_ROUTE}`,
            articles: true
        },
        {
            label: 'Vakalar',
            path: `/${BASE_ROUTE}`,
            articles: true
        },
        {
            label: 'Fiyatlandırma', // перевести
            path: `/pricing`,
        },
    ]

    const current_menu = []
    const btn = [
        {
            label: LANGS.TUR,
            className: 'btn__language_tur'
        },
        {
            label: LANGS.ENG,
            className: 'btn__language_en'
        }
    ]

    const gropBtn = btn.map(({ label, className }, ind) => <button
        key={label + ind}
        onClick={() => {
            setActiveLang(label)
            setIsOpenSelect(false)
        }}
        className={'btn__language' + ' ' + className + " " + (label === activeLang ? "btn__language__active" : '')}>{label && label?.toUpperCase()}</button>)

    const navMenuItems = current_menu.map(({ label, value, subItemsMenu, articles, path }, ind) =>
        <div className={activeMenuItems == label ? 'menu-items-wrap_active' : 'menu-items-wrap'} tabIndex='0' key={label + path + ind}
            onClick={(e) => setActiveMenuItems(label)}
            onMouseOver={(e) => setIsOpenMenu(label)}
            onMouseOut={(e) => setIsOpenMenu(null)}
        >
            <Link to={articles ? `main/${BASE_ROUTE}/${value ? value : label}` : path} key={label} className='header__nav-item'>{label}</Link>
            {subItemsMenu ? <DropMenuItem isOpenMenu={isOpenMenu} parentLabel={label} subItemsMenu={subItemsMenu} /> : null}
        </div>
    )

    const navMenuItemsMobile = current_menu.map(({ label, subItemsMenu, path, article }, ind) =>
        <div className='menu-items-wrap' tabIndex='0'>
            <Link to={`/${BASE_ROUTE}/${label}`} key={label} className='header__nav-item'>{label}</Link>
            {subItemsMenu ? <DropMenuMobileVersion parentLabel={label} subItemsMenu={subItemsMenu} /> : null}
        </div>
    )

    useEffect(() => {
        setIsOpenMenu(null)
    }, [path]);

    useEffect(() => {
        const tabLang = document.querySelector('.select-language')
        const closeLanguageTab = (e) => {
            let el = e.target
            let btn = el.closest('.language-selection')
            let box = el.closest('.select-language')

            if (btn || box) {
                return
            } else {
                setIsOpenSelect(false)
            }
        }

        document.addEventListener('click', closeLanguageTab)

        return () => {
            document.removeEventListener('click', closeLanguageTab)
        }
    }, [])

    return (
        <header className='header'>
            <div className='header__content'>
                <div className='nav-group'>
                    {(isOpenInput && widtWindow < 420) ? null : <LogoBox />}
                    <div className='btn-and-text'>
                        {(isOpenInput) ? null : <>
                            <a href='https://lk.gtcom.io/auth' target="_blank"><button className='btn-violet'>{translateLogin()}</button></a>
                            <a href='https://lk.gtcom.io/auth/signup' target="_blank"><button className='btn-violet-border'>{translateSignup()}</button></a>
                            <div className='language-selection' onClick={() => setIsOpenSelect(!isOpenSelect)}>
                                {isOpenSelect && <div className='select-language'>{gropBtn}</div>}
                                <img src={lang} />
                                <p className={`text ${isOpenSelect ? 'opened' : ''}`}>
                                    {widtWindow > 420 ? activeLang.toUpperCase() : ''}
                                </p>
                            </div>
                            <button className={isOpenMobileMenu ? 'btn-close' : 'btn-burger'} onClick={(e) => setIsOpenMobileMenu(!isOpenMobileMenu)}></button>
                        </>}

                    </div>
                </div>

                <nav className='header__nav'>
                    {/*  */}
                    {widtWindow < 420 ?
                        <DropMenuMobileVersion items={current_menu} isOpenMobileMenu={isOpenMobileMenu} setOpen={(e) => setIsOpenMobileMenu(e)} />
                        : navMenuItems
                    }
                </nav>
            </div>
        </header >
    )
}

export { Header }