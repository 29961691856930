import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={< App />} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);
