import { Link } from "react-router-dom"
import { LANGS } from "../../../App"
import "./CopyrightBox.scss"

const CopyrightBox = ({ activeLang }) => {

    return (
        <>
            {activeLang === LANGS.TUR ?
                <section className='copyright'>
                    <Link to="#" className="footer__copyright">© GTCOM.IO 2024 Tüm hakları saklıdır.</Link>
                    <Link to="/main/article/Terms_of_Service/578" className="footer__link">Kullanıcı sözleşmesi</Link>
                    <Link to="/main/article/Terms_of_payment/587" className="footer__link">Ödeme politikası</Link>
                    <Link to="/main/article/PRIVACY_POLICY/579" className="footer__link">Gizlilik politikası</Link>
                    <Link to="#" className="footer__link country" style={{ 'border': 'none' }}>Türkiye</Link>
                </section>
                :
                <section className='copyright'>
                    <a href="#" className="footer__copyright">© GTCOM.IO 2024 All rights reserved.</a>
                    <a href="https://gtcom.io/articles/Policies%20/Terms_of_Service/578" target='_blank' className="footer__link">User Agreement</a>
                    <a href="https://gtcom.io/articles/Policies%20/Terms_of_payment/587" target='_blank' className="footer__link">Payment Politics</a>
                    <a href="https://gtcom.io/articles/Policies%20/PRIVACY_POLICY/579" target='_blank'  className="footer__link">Confidential Politics</a>
                    <a href="#" className="footer__link country" style={{ 'border': 'none' }}>Turkey</a>
                </section>
            }
        </>

    )
}

export { CopyrightBox }