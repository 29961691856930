import Footer from './components/Footer'
import { Helmet } from 'react-helmet-async';
import { Header } from './components/Header/Header';
import { Page404 } from './pages/Page404/Page404'
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Routes, Route } from 'react-router-dom';
import { ArticleMainContainer } from '@lk-gtcom/ecomlab-components'
import './App.scss';
import './index.scss';

export const LANGS = {
  TUR: 'tur',
  ENG: 'en'
}

export const PORTAL_ID = 5
export const BASE_ROUTE = 'articles'

const getLanguage = () => {
  const storedLang = localStorage.getItem('lang')
  return Object.values(LANGS).includes(storedLang) ? storedLang : LANGS.ENG
}

function App() {
  const [activeLang, setActiveLang] = useState(getLanguage())
  const [activeMenuItems, setActiveMenuItems] = useState(null)
  const navigate = useNavigate()
  const path = useParams()

  useEffect(() => {
    localStorage.setItem('lang', 'en')
    const articleId = sessionStorage.getItem('articleId')
    const currPath = path['*']
    if (articleId) {
      if (currPath.indexOf(`/article/${articleId}`) !== -1) {
        navigate(`/article/${articleId}`)
      }
    } else {
      navigate('/articles/main')
    }
  }, []);

  const setLanguage = lang => {
    let langForHtml
    switch (lang) {
      case LANGS.ENG:
        langForHtml = 'en'
        break;
      case LANGS.TUR:
        langForHtml = 'tr'
        break;
      case LANGS.RUS:
        langForHtml = 'ru'
        break;
      default:
        throw new Error('unknown language')
    }
    document.documentElement.setAttribute('lang', langForHtml)
    localStorage.setItem('lang', lang)
    setActiveLang(lang)
  }

  let pathBread = path['*'].split('/')[0]

  return (
    <div className="App" >
      <Helmet>
        <script type="application/ld+json">
          {
            `{
                    "@context":"https://schema.org/",
                    "@type":"Organization",
                    "name": "GTCOM",
                    "telephone":"+78003016563",
                    "email":"info@gtcom.io",
                    "address":{
                        "@type":"PostalAddress",
                        "postalCode":"117342",
                        "addressCountry":"Türkiye",
                        "addressLocality":"İstanbul",
                        "streetAddress":"iCERENKOY MAHALLESI USKUDAR- iCERENKOY YOLU CAD. OZIS SiT. OFiS ATASEHIR AP. NO 21/6"
                    }
              }`
          }
        </script>
      </Helmet>
      {/* Основное меню */}
      <Header
        activeLang={activeLang}
        setActiveLang={(e) => setLanguage(e)}
        activeMenuItems={activeMenuItems}
        setActiveMenuItems={(e) => setActiveMenuItems(e)}
      />


      <div className='main'>
        <Routes>
          {/* 404 */}
          <Route path='/*' element={
            <ArticleMainContainer
              LANGS={LANGS}
              BASE_ROUTE={BASE_ROUTE}
              PORTAL_ID={PORTAL_ID}
              activeLang={activeLang}
              pathBread={pathBread} />}
          />
          <Route path='/*' element={<Page404 activeLang={activeLang} />} />
        </Routes >
      </div>
      <Footer activeLang={activeLang} portal_id={PORTAL_ID} />
    </div>
  )
}

export default App;
