import { Link } from 'react-router-dom'
import './FooterItem.scss'

const FooterItem = ({ label, subItems }) => {

    const footerLinks = subItems.map(({ label, path, blank }, ind) => {
        return (
            <li key={label + ind} className='footer__list_item'>
                <a href={path ? path : '#'} target={blank ? '_blank' : ''} className="footer__link">{label}</a>
            </li>
        );
    });

    return (
        <div className="footer__item">
            <h4 className="footer__title">{label}</h4>
            <ul className="footer__list">
                {footerLinks}
            </ul>
        </div>
    )
}
export { FooterItem }